import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-action',
    templateUrl: './info-action.component.html',
    styleUrls: ['./info-action.component.scss'],
})
export class InfoActionComponent {
    @Input() actionBy: string;
    @Input() actionAt: Date;
}
