export const environment = {
    production: false,
    apiUrl: 'https://fys-platform-be-test.azurewebsites.net/api/',
    clientId: 'c19fcd84-4459-4077-8b93-5db2c90d1a78',
    authority: 'https://fysPlatformTest.b2clogin.com/fysPlatformTest.onmicrosoft.com/B2C_1_SignIn',
    authorityDomain: 'fysPlatformTest.b2clogin.com',
    redirectUri: 'https://fys-test.scryptsoftware.com',
    endpoint: 'https://fys-platform-be-test.azurewebsites.net/',
    endpointScopes: ['https://fysPlatformTest.onmicrosoft.com/6286d673-7b9c-4f5a-9245-4a66a00db8a8/All'],
    key: 'Lj9z7sA2tRNLj5Jc3N4pUf7tF8gS6xYs',
    iv: "P6f9s4H8j1E3d7K2"
};
