import { Component, OnInit, Input } from '@angular/core';
import { ApprovedSubmission } from 'app/shared/models/dashboard';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-approved-submissions',
    templateUrl: './approved-submissions.component.html',
    styleUrls: ['./approved-submissions.component.scss'],
})
export class ApprovedSubmissionComponent implements OnInit {
    @Input() projectId: string;
    public approvedSubmissions: ApprovedSubmission[] = [];
    public loadingPage = true;

    constructor(
        private dashboardService: DashboardService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getApprovedSubmission();
    }

    getApprovedSubmission() {
        this.dashboardService
            .getApprovedSubmission(this.projectId)
            .subscribe((res) => {
                this.approvedSubmissions = res;
                this.loadingPage = false;
            });
    }

    goToStep(projectId: string, unitId: string, stepId: string) {
        this.router.navigate([
            '/projects/' + projectId + '/units/' + unitId + '/steps/' + stepId,
        ]);
    }
}
