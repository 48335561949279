<p-skeleton *ngIf="loadingPage" borderRadius="1rem" height="15rem"></p-skeleton>
<div *ngIf="!loadingPage"
    class="col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden h-full">
    <div class="text-lg font-medium tracking-tight leading-6 truncate mb-2">
        {{ 'Approved' | translate }}
    </div>
    <div class="grid grid-cols-2 h-full">
        <div class="col-span-2 max-h-40 flex flex-col flex-auto overflow-auto">
            <ng-container *ngIf="approvedSubmissions.length === 0">
                <div class="text-center">
                    <p class="text-2xl font-bold">{{'No Information' | translate}}</p>
                </div>
            </ng-container>
            <ng-container *ngFor="let approvedSubmission of approvedSubmissions; let last = last">
                <div class="flex flex-row whitespace-nowrap items-center align-center cursor-pointer leading-8 hover:bg-slate-100"
                    [ngClass]="{ 'border-b': !last }" [ngbTooltip]="submissionInfo" placement="top" openDelay="600"
                    container="body"
                    (click)="goToStep(approvedSubmission.projectId, approvedSubmission.unitId, approvedSubmission.stepId)">
                    <div *ngIf="!projectId" class="opacity-75 truncate max-w-[16%]"
                        [ngbTooltip]="approvedSubmission.projectName" placement="bottom" openDelay="600"
                        container="body">{{
                        approvedSubmission.projectName
                        }}</div>
                    <div class="opacity-75 truncate max-w-[16%]" [ngbTooltip]="approvedSubmission.unitName"
                        placement="bottom" openDelay="600" container="body">&nbsp;/&nbsp;{{
                        approvedSubmission.unitName }}</div>
                    <div class="mr-2">&nbsp;/&nbsp;<b>{{ approvedSubmission.stepName }}</b></div>
                    <div class="flex-grow"></div>
                    <div class="mt-1">
                        <mat-icon *ngFor="let star of [1, 2, 3, 4, 5]; let i = index" class="icon-size-5 star-default"
                            [svgIcon]="'heroicons_solid:star'"
                            [ngClass]="{ 'star-filled': i < approvedSubmission.approvalRating }"></mat-icon>
                    </div>
                </div>
                <ng-template #submissionInfo>
                    <div class="row col-12">
                        {{ 'Submission Date' | translate }}:&nbsp;{{ approvedSubmission?.submissionDate | date }}<br />
                        {{ 'User' | translate }}:&nbsp;{{ approvedSubmission?.userName }}<br />
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
