import { Component, Input, OnInit } from '@angular/core';
import { Progress } from 'app/shared/models/dashboard';
import { ApexOptions } from 'ng-apexcharts';
import { DashboardService } from 'app/shared/services/dashboard.service';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
    @Input() projectId: string;
    public progress: Progress = new Progress();
    public chartProgress: ApexOptions = {};
    public loadingPage = true;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {
        this.getProgress();
    }

    getProgress() {
        this.dashboardService.getProgress(this.projectId).subscribe((res) => {
            this.progress = res;
            this.loadingPage = false;
            this.prepareChartProgress();
        });
    }

    prepareChartProgress() {
        this.chartProgress = {
            chart: {
                height: 380,
                type: 'radialBar',
                sparkline: {
                    enabled: true,
                },
            },
            series: [this.progress.percentage],
            colors: ['#5453f6'],
            labels: ['Completed'],
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '50%',
                    },
                    dataLabels: {
                        name: {
                            offsetY: 20,
                            color: '#292a6a',
                            fontSize: '16px',
                        },
                        value: {
                            offsetY: -20,
                            color: '#5453f6',
                            fontSize: '28px',
                        },
                    },
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'vertical',
                    gradientToColors: ['#87D4F9'],
                    stops: [0, 100],
                },
            },
            stroke: {
                lineCap: 'round',
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    },
                },
                active: {
                    filter: {
                        type: 'none',
                    },
                },
            },
        };
    }
}
