import { Component, Input } from '@angular/core';
import * as alertFunctions from 'app/shared/models/sweet-alerts';

@Component({
    selector: 'app-sweet-alerts',
    templateUrl: './sweet-alerts.component.html',
    styleUrls: ['./sweet-alerts.component.scss'],
})
export class SweetAlertsComponent {
    @Input() message: string;

    basicAlert() {
        alertFunctions.errorAlert(this.message);
    }
}
