import { Component, Input, OnInit } from '@angular/core';
import { SummaryTask } from 'app/shared/models/dashboard';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-summary-tasks',
    templateUrl: './summary-tasks.component.html',
    styleUrls: ['./summary-tasks.component.scss'],
})
export class SummaryTasksComponent implements OnInit {
    @Input() projectId: string;
    public summaryTasks: SummaryTask[] = [];
    public loadingPage = true;

    constructor(
        private dashboardService: DashboardService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getSummaryTasks();
    }

    getSummaryTasks() {
        this.dashboardService
            .getSummaryTasks(this.projectId)
            .subscribe((res) => {
                this.summaryTasks = res;
                this.loadingPage = false;
            });
    }

    goToStep(projectId: string, unitId: string, stepId: string) {
        this.router.navigate([
            '/projects/' + projectId + '/units/' + unitId + '/steps/' + stepId,
        ]);
    }
}
