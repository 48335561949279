<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <h2 class="text-xl font-semibold py-2 px-4 modal-title">
        {{ 'Description' | translate }}
    </h2>
    <div class="m-4">
        <div class="text-justify" style="word-wrap: break-word;">
            {{description}}
        </div>
        <div>
            <div class="flex items-center justify-end mt-4">
                <button class="mr-1" mat-flat-button [color]="'accent'" (click)="closeModalEvent.emit()">
                    {{'Close' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
