export class Progress {
    percentage: number;
}

export class SummaryTask {
    projectId: string;
    projectName: string;
    unitId: string;
    unitName: string;
    stepId: string;
    stepName: string;
    dueDate: Date;
}

export class ApprovedSubmission {
    projectId: string;
    projectName: string;
    unitId: string;
    unitName: string;
    stepId: string;
    stepName: string;
    approvalRating: number;
    submissionDate: Date;
    userName: string;
}
