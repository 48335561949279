import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
class Skeleton {
  constructor() {
    this.shape = 'rectangle';
    this.animation = 'wave';
    this.borderRadius = null;
    this.size = null;
    this.width = '100%';
    this.height = '1rem';
  }
  containerClass() {
    return {
      'p-skeleton p-component': true,
      'p-skeleton-circle': this.shape === 'circle',
      'p-skeleton-none': this.animation === 'none'
    };
  }
  containerStyle() {
    if (this.size) return {
      ...this.style,
      width: this.size,
      height: this.size,
      borderRadius: this.borderRadius
    };else return {
      ...this.style,
      width: this.width,
      height: this.height,
      borderRadius: this.borderRadius
    };
  }
}
Skeleton.ɵfac = function Skeleton_Factory(t) {
  return new (t || Skeleton)();
};
Skeleton.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: Skeleton,
  selectors: [["p-skeleton"]],
  hostAttrs: [1, "p-element"],
  inputs: {
    styleClass: "styleClass",
    style: "style",
    shape: "shape",
    animation: "animation",
    borderRadius: "borderRadius",
    size: "size",
    width: "width",
    height: "height"
  },
  decls: 1,
  vars: 4,
  consts: [[3, "ngClass", "ngStyle"]],
  template: function Skeleton_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
    }
    if (rf & 2) {
      i0.ɵɵclassMap(ctx.styleClass);
      i0.ɵɵproperty("ngClass", ctx.containerClass())("ngStyle", ctx.containerStyle());
    }
  },
  dependencies: [i1.NgClass, i1.NgStyle],
  styles: [".p-skeleton{position:relative;overflow:hidden}.p-skeleton:after{content:\"\";animation:p-skeleton-animation 1.2s infinite;height:100%;left:0;position:absolute;right:0;top:0;transform:translate(-100%);z-index:1}.p-skeleton.p-skeleton-circle{border-radius:50%}.p-skeleton-none:after{animation:none}@keyframes p-skeleton-animation{0%{transform:translate(-100%)}to{transform:translate(100%)}}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Skeleton, [{
    type: Component,
    args: [{
      selector: 'p-skeleton',
      template: ` <div [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="containerStyle()"></div> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: [".p-skeleton{position:relative;overflow:hidden}.p-skeleton:after{content:\"\";animation:p-skeleton-animation 1.2s infinite;height:100%;left:0;position:absolute;right:0;top:0;transform:translate(-100%);z-index:1}.p-skeleton.p-skeleton-circle{border-radius:50%}.p-skeleton-none:after{animation:none}@keyframes p-skeleton-animation{0%{transform:translate(-100%)}to{transform:translate(100%)}}\n"]
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    borderRadius: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }]
  });
})();
class SkeletonModule {}
SkeletonModule.ɵfac = function SkeletonModule_Factory(t) {
  return new (t || SkeletonModule)();
};
SkeletonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SkeletonModule
});
SkeletonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SkeletonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Skeleton],
      declarations: [Skeleton]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Skeleton, SkeletonModule };

