import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-info-description',
    templateUrl: './info-description.component.html',
    styleUrls: ['./info-description.component.scss'],
})
export class DescriptionModalComponent {
    @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public descriptionDialogRef: MatDialogRef<DescriptionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public description: string
    ) {}
}
