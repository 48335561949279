<p-skeleton *ngIf="loadingPage" borderRadius="1rem" height="31.8rem"></p-skeleton>
<div *ngIf="!loadingPage"
    class="col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
    <div class="text-lg font-medium tracking-tight leading-6 truncate">{{'Progress' | translate}}
    </div>
    <div *ngIf="progress?.percentage >= 0" class="flex flex-col items-center">
        <apx-chart class="flex flex-auto items-center justify-center w-full h-full" [chart]="chartProgress.chart"
            [series]="chartProgress.series" [colors]="chartProgress.colors" [labels]="chartProgress.labels"
            [plotOptions]="chartProgress.plotOptions" [fill]="chartProgress.fill" [stroke]="chartProgress.stroke"
            [states]="chartProgress.states"></apx-chart>
    </div>
</div>
