import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApprovedSubmission, Progress, SummaryTask } from '../models/dashboard';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private httpClient: HttpClient) {}

    getProgress(projectId: string): Observable<Progress> {
        let params = new HttpParams();

        if (projectId) {
            params = params.append('projectId', projectId);
        }

        return this.httpClient.get<Progress>(
            `${environment.apiUrl}dashboards/opentasks`,
            {
                params: params,
            }
        );
    }

    getSummaryTasks(projectId: string): Observable<SummaryTask[]> {
        let params = new HttpParams();

        if (projectId) {
            params = params.append('projectId', projectId);
        }

        return this.httpClient.get<SummaryTask[]>(
            `${environment.apiUrl}dashboards/taskssummary`,
            {
                params: params,
            }
        );
    }

    getApprovedSubmission(projectId: string): Observable<ApprovedSubmission[]> {
        let params = new HttpParams();

        if (projectId) {
            params = params.append('projectId', projectId);
        }

        return this.httpClient.get<ApprovedSubmission[]>(
            `${environment.apiUrl}dashboards/approvedsubmissions`,
            {
                params: params,
            }
        );
    }
}
