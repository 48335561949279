import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { DescriptionModalComponent } from './components/info-description/info-description.component';
import { AuditableComponent } from './components/auditable/auditable.component';
import { InfoActionComponent } from './components/info-action/info-action.component';
import { SweetAlertsComponent } from './components/sweet-alerts/sweet-alerts.component';
import { ProgressComponent } from './components/dashboards/progress/progress.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SummaryTasksComponent } from './components/dashboards/summary-tasks/summary-tasks.component';
import { ApprovedSubmissionComponent } from './components/dashboards/approved-submissions/approved-submissions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SkeletonModule } from 'primeng/skeleton';

@NgModule({
    declarations: [
        DescriptionModalComponent,
        AuditableComponent,
        InfoActionComponent,
        SweetAlertsComponent,
        ProgressComponent,
        SummaryTasksComponent,
        ApprovedSubmissionComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButtonModule,
        NgApexchartsModule,
        MatTooltipModule,
        MatIconModule,
        NgbTooltipModule,
        SkeletonModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DescriptionModalComponent,
        AuditableComponent,
        InfoActionComponent,
        ProgressComponent,
        SummaryTasksComponent,
        ApprovedSubmissionComponent,
    ],
})
export class SharedModule {}
