import { Component, Input } from '@angular/core';
import { IAuditable } from 'app/shared/models/iauditable';

@Component({
    selector: 'app-auditable',
    templateUrl: './auditable.component.html',
    styleUrls: ['./auditable.component.scss'],
})
export class AuditableComponent {
    @Input() audi: IAuditable;
}
