import swal from 'sweetalert2';

export function errorAlert(message: string) {
    swal.fire({
        title: 'Error!',
        html: '<p style="text-align: left;">' + message + '</p>',
        icon: 'error',
        confirmButtonText: 'OK',
    });
}
