<p-skeleton *ngIf="loadingPage" borderRadius="1rem" height="15rem"></p-skeleton>
<div *ngIf="!loadingPage"
    class="col-span-2 flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden h-full">
    <div class="text-lg font-medium tracking-tight leading-6 truncate mb-2">{{'Summary' | translate}}</div>
    <div class="grid grid-cols-3">
        <div class="col-span-1 flex flex-col justify-center items-center">
            <div class="text-8xl sm:text-9xl font-bold tracking-tight leading-none text-primary-500">
                {{summaryTasks?.length}}</div>
            <div class="text-lg font-medium text-primary-600 dark:text-primary-500">{{'Tasks' | translate}}</div>
        </div>
        <div class="col-span-2 max-h-40 flex flex-col flex-auto overflow-auto">
            <ng-container *ngFor="let summaryTask of summaryTasks; let last = last">
                <div class="flex flex-row whitespace-nowrap items-center align-center cursor-pointer leading-8 hover:bg-slate-100"
                    [ngClass]="{ 'border-b': !last }" [ngbTooltip]="dueDateInfo" placement="top" openDelay="600"
                    container="body" (click)="goToStep(summaryTask.projectId, summaryTask.unitId, summaryTask.stepId)">
                    <div *ngIf="!projectId" class="opacity-75 truncate max-w-[16%]"
                        [ngbTooltip]="summaryTask.projectName" placement="bottom" openDelay="600" container="body">{{
                        summaryTask.projectName
                        }}&nbsp;/&nbsp;</div>
                    <div class="opacity-75 truncate max-w-[16%]" [ngbTooltip]="summaryTask.unitName" placement="bottom"
                        openDelay="600" container="body">{{
                        summaryTask.unitName }}</div>
                    <div class="mr-2">&nbsp;/&nbsp;<b>{{ summaryTask.stepName }}</b></div>
                </div>
                <ng-template #dueDateInfo>
                    <div class="row">
                        {{ 'Due Date' | translate }}:
                        {{ summaryTask?.dueDate | date }}
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>
